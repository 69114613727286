import React from 'react';
import profilePicture from './profile.png';
import './App.css';

function App() {
	return (
		<div className="App">
			<div className="banner-text">
				<h1>WORK IN PROGRESS</h1>
			</div>
			<header className="App-header">
				<div className="Left-header">
					<div className="Profile-picture">
						<img src={profilePicture} alt="Ahmad Ridzwan Zainuddin Georges" />
					</div>
					<div className="Profile-text">
						<h2>Ahmad Ridzwan Zainuddin Georges</h2>
						<h3>Software Developer</h3>
					</div>
				</div>
				<div className="Middle-header">
					<h1>Career</h1>
				</div>
				<div className="Right-header">
					<h1>Education</h1>
				</div>
			</header>
		</div>
	);
}

export default App;
